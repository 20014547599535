import React, { Fragment, useState, useEffect } from "react";
import { Link } from "gatsby";
import { FaAngleUp, FaTwitter, FaFacebook, FaInstagram } from "react-icons/fa";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { social } from "../modules/settings";
const Footer = () => {
  const [scrollBtn, setScrollBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setScrollBtn(true);
    } else if (window.scrollY < 100) {
      setScrollBtn(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Fragment>
      <section className="footer-area">
        <div className="footer-top">
          <div className="container">
            <div className="row footer-widget-wrap">
              <div className="col footer-item footer-item1">
                <h3 className="widget__title">Home</h3>
                <ul className="foot__links">
                  <li>
                    <AnchorLink to="/">Home</AnchorLink>
                  </li>

                  <li>
                    <AnchorLink to="/mission/#mission">Who we are</AnchorLink>
                  </li>
                  <li>
                    <AnchorLink to="/mission/#council">
                      Advisory Council
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink to="/news">Our News</AnchorLink>
                  </li>
                  <li>
                    <AnchorLink to="/#joinus">Join us</AnchorLink>
                  </li>
                </ul>
              </div>
              <div className="col footer-item footer-item3">
                <h3 className="widget__title">links</h3>
                <ul className="foot__links">
                  <li>
                    <Link to="/privacy">Privacy</Link>
                  </li>
                </ul>
              </div>

              <div className="col footer-item footer-item4">
                <h3 className="widget__title">contact</h3>
                <ul className="contact__info">
                  <li>
                    ACTION FOR DEMOCRACY <br /> 308 S Jefferson St STE 202{" "}
                    <br /> CHICAGO, IL 60661
                  </li>
                  <li>
                    <a
                      href="mailto:info@actionfordemocracy.org"
                      rel="noreferrer noopener"
                      className="text-lowercase"
                    >
                      info [at] actionfordemocracy.org
                    </a>
                  </li>
                </ul>
                <div className="footer__social">
                  <ul>
                    <li>
                      <a href={social.facebookPage}>
                        <FaFacebook />
                      </a>
                    </li>
                    {/* <li>
                      <a href={social.twitterPage}>
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href={social.instagramPage}>
                        <FaInstagram />
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="copyright-desc">
                  <p>
                    © Copyright {new Date().getFullYear()} by{" "}
                    actionfordemocracy.org
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        role="button"
        onKeyUp={scrollTop}
        tabIndex="0"
        onClick={scrollTop}
        id="back-to-top"
        className={scrollBtn ? "back-btn-shown" : ""}
      >
        <FaAngleUp title="go up" />
      </div>
    </Fragment>
  );
};

export default Footer;
