import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { FaTwitter, FaFacebook, FaInstagram, FaBars } from "react-icons/fa";
import { social } from "../modules/settings";

import { isBrowser } from "../modules/isBrowser";

const NavOne = () => {
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    isBrowser && window.addEventListener("scroll", handleScroll);
    //Mobile Menu
    mobileMenu();
  });
  const handleScroll = () => {
    if (isBrowser && window?.scrollY > 100) {
      setSticky(true);
    } else if (isBrowser && window?.scrollY < 100) {
      setSticky(false);
    }
  };

  const mobileMenu = () => {
    //Mobile Menu Toggle
    let mainNavToggler =
      isBrowser && document.querySelector(".mobile-menu-toggle");
    let mainNav = isBrowser && document.querySelector(".side-nav-container");

    mainNavToggler.addEventListener("click", function () {
      mainNav.classList.add("active");
    });

    //Close Mobile Menu
    let closeMenu = isBrowser && document.querySelector(".side-menu-close");
    closeMenu.addEventListener("click", function () {
      mainNav.classList.remove("active");
    });
  };

  const DesktopMenu = () => (
    <div className="col-lg-8 col-sm-8 ostion-menu">
      <div className="ostion-menu-innner">
        <div className="ostion-menu-content">
          <div className="navigation-top">
            <nav className="main-navigation">
              <ul>
                <li>
                  <span className="menu-hashtag">
                    <i>#HungarianSpring</i>
                  </span>
                </li>
                <li>
                  <AnchorLink to="/">Home</AnchorLink>
                </li>
                <li>
                  <AnchorLink to="/mission/#mission">Who we are</AnchorLink>
                </li>
                <li>
                  <AnchorLink to="/mission/#council">
                    Advisory Council
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink to="/news">Our News</AnchorLink>
                </li>
                <li>
                  <AnchorLink to="/#joinus">Join us</AnchorLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="mobile-menu-toggle">
          <FaBars />
        </div>
      </div>
    </div>
  );

  const SideMenu = () => (
    <div className="side-nav-container">
      <div className="humburger-menu">
        <div className="humburger-menu-lines side-menu-close"></div>
      </div>
      <div className="side-menu-wrap">
        <ul className="side-menu-ul">
          <li className="sidenav__item">
            <AnchorLink to="/">Home</AnchorLink>
          </li>
          <li className="sidenav__item">
            <AnchorLink to="/mission/#mission">Who we are</AnchorLink>
          </li>
          <li className="sidenav__item">
            <AnchorLink to="/mission/#council">Advisory Council</AnchorLink>
          </li>
          <li className="sidenav__item">
            <AnchorLink to="/news">Our News</AnchorLink>
          </li>
          <li className="sidenav__item">
            <AnchorLink to="/#joinus">Join us</AnchorLink>
          </li>
        </ul>
        <ul className="side-social">
          <li>
            <a href={social.facebookPage}>
              <FaFacebook />
            </a>
          </li>
          {/* <li>
            <a href={social.twitterPage}>
              <FaTwitter />
            </a>
          </li>
          <li>
            <a href={social.instagramPage}>
              <FaInstagram />
            </a>
          </li> */}
        </ul>
        <div className="side-btn">
          <a
            href="https://secure.actblue.com/donate/a4d-website?refcode=website&amount=25"
            target="_blank"
            rel="noreferrer noopener"
            className="theme-btn"
          >
            donate now
          </a>
        </div>
      </div>
    </div>
  );
  return (
    <div>
      <header className="header-area">
        <div
          className={`header-top header-menu-action ${
            sticky ? "header-fixed" : ""
          }`}
        >
          <div className="container-fluid">
            <div className="row ostion-top-wrap">
              <div className="col-lg-4 col-sm-4 site-branding">
                <div className="logo-action d-flex align-items-center">
                  <div className="ostion-logo">
                    <Link to="/">
                      <StaticImage
                        src="../images/logo_a4d_white.png"
                        alt="ActionForDemocracy"
                        title="ActionForDemocracy"
                        className="top-headr-logo"
                        // width={140}
                        // height={105}
                      />
                    </Link>
                  </div>
                  <div className="header-btn ml-auto">
                    <ul>
                      <li className="mb-2">
                        <a
                          href="https://secure.actblue.com/donate/a4d-website?refcode=website&amount=25"
                          target="_blank"
                          rel="noreferrer noopener"
                          className="theme-btn theme-btn-2"
                        >
                          donate now
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://act.actionfordemocracy.org/fundraising/donate-hungarian-spring/"
                          target="_blank"
                          rel="noreferrer noopener"
                          className="theme-btn"
                        >
                          Hungarian? donate here
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <DesktopMenu />
            </div>
          </div>
        </div>
        <SideMenu />
      </header>
    </div>
  );
};

export default NavOne;
