export const seo = {
  description: "Join our movement to support free and democratic elections!",
  ogTitle: "Action for Democracy - United for Democracy",
  ogDescription: "Join our movement to support free and democratic elections!",
  ogType: "website",
  ogUrl: "https://actionfordemocracy.org",
  ogImage:
    "https://actionfordemocracy.org/static/illu1_bw-61fdb0cce5e031ee9eaf2737c4fe632a.jpg",
  ogImageUrl:
    "https://actionfordemocracy.org/static/illu1_bw-61fdb0cce5e031ee9eaf2737c4fe632a.jpg",
  ogImageWidth: 512,
  ogImageHeight: 205,
  twitterCard: "summary",
  twitterSite: "@",
  twitterTitle: "Action for Democracy - United for Democracy",
  twitterDescription:
    "Join our movement to support free and democratic elections!",
  twitterImage:
    "https://actionfordemocracy.org/static/illu1_bw-61fdb0cce5e031ee9eaf2737c4fe632a.jpg",
  facebookDomainVerification: "",
};

export const social = {
  facebookPage: "https://www.facebook.com/Action-for-Democracy-102400579013256",
  twitterPage: "",
  twitterHandle: "",
  instagramPage: "",
};
