import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import favicon from "../images/favicon.png";
import { seo } from "../modules/settings";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/font-awesome.css";
import "../assets/css/animate.min.css";
import "../assets/css/style.css";
import "../assets/css/responsive.css";
import "swiper/css";

const Layout = (props) => {
  return (
    <Fragment>
      <Helmet>
        <title>{seo.ogTitle}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <link rel="icon" href={favicon} />
        <meta name="description" content={seo.description} />
        <meta name="og:title" content={seo.ogTitle} />
        <meta name="og:description" content={seo.ogDescription} />
        <meta name="og:type" content={seo.ogType} />
        <meta name="og:url" content={seo.ogUrl} />
        <meta name="og:image" content={seo.ogImage} />
        <meta name="og:image:url" content={seo.ogImageUrl} />
        <meta name="og:image:width" content={seo.ogImageWidth} />
        <meta name="og:image:height" content={seo.ogImageHeight} />
        <meta name="twitter:card" content={seo.twitterCard} />
        <meta name="twitter:site" content={seo.twitterSite} />
        <meta name="twitter:title" content={seo.twitterTitle} />
        <meta name="twitter:description" content={seo.twitterDescription} />
        <meta name="twitter:image" content={seo.twitterImage} />
        <meta
          name="facebook-domain-verification"
          content={seo.facebookDomainVerification}
        />
      </Helmet>
      {props.children}
    </Fragment>
  );
};
export default Layout;
